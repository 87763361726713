
const CLOUDFRONT_ENDPOINT = 'https://d1wqfechwjsewm.cloudfront.net/';


const MATRIMONY_CLOUDFRONT = 'https://ds290biduyry8.cloudfront.net/'
const S3_PRESIGN_URL = 'https://v2ao5dt0v3.execute-api.ap-south-1.amazonaws.com/prod/v1/data/presign/url';
// dev
// const EMAIL_SERVER_URL = 'http://localhost:7878/api';
// const SERVERLESS_URL = 'http://localhost:7878/api';
// const SMS_SERVER_URL = 'http://localhost:7878/api';
// const OPEN_API_ENDPOINT = 'http://localhost:7878/api';
//const TINY_BASE_URL = 'http://localhost:7878'


const stage = 'seererver-ip';

// http://38.242.141.151:8521

// // prod

// sas-prod
var EMAIL_SERVER_URL = 'https://z8tfb7ipzc.execute-api.ap-south-1.amazonaws.com/prod/api';
var SMS_SERVER_URL = 'https://z8tfb7ipzc.execute-api.ap-south-1.amazonaws.com/prod/api';
var SERVERLESS_URL = 'https://z8tfb7ipzc.execute-api.ap-south-1.amazonaws.com/prod/api'
var OPEN_API_ENDPOINT = 'https://z8tfb7ipzc.execute-api.ap-south-1.amazonaws.com/prod/api';

var PRESIGN_URL_ENDPOINT = `https://v2ao5dt0v3.execute-api.ap-south-1.amazonaws.com/prod/v1/data`;
var REPORT_URL = `https://btycikcg7g.execute-api.ap-south-1.amazonaws.com/prod/v1/data`;

if (stage === 'server-ip') {
    EMAIL_SERVER_URL = 'http://38.242.141.151:8521/api';
    SMS_SERVER_URL = 'http://38.242.141.151:8521/api';
    SERVERLESS_URL = 'http://38.242.141.151:8521/api'
    OPEN_API_ENDPOINT = 'http://38.242.141.151:8521/api';


    // PRESIGN_URL_ENDPOINT = `http://localhost:3000/v1/data`;

    PRESIGN_URL_ENDPOINT = `https://v2ao5dt0v3.execute-api.ap-south-1.amazonaws.com/prod/v1/data`;


    REPORT_URL = `https://btycikcg7g.execute-api.ap-south-1.amazonaws.com/prod/v1/data`;

}


if (stage === 'local-ip') {
    EMAIL_SERVER_URL = 'http://localhost:7878/api';
    SMS_SERVER_URL = 'http://localhost:7878/api';
    SERVERLESS_URL = 'http://localhost:7878/api'
    OPEN_API_ENDPOINT = 'http://localhost:7878/api';


    PRESIGN_URL_ENDPOINT = `http://localhost:7878/v1/data`;

    REPORT_URL = `https://btycikcg7g.execute-api.ap-south-1.amazonaws.com/prod/v1/data`;

}





const TINY_BASE_URL = 'sstiny.in'
const ORG_CODE = 'aau'

//const TINY_BASE_URL = 'sms.sstiny.com'

module.exports = {
    EMAIL_SERVER_URL,
    SERVERLESS_URL,
    OPEN_API_ENDPOINT,
    SMS_SERVER_URL,
    TINY_BASE_URL,
    ORG_CODE,
    CLOUDFRONT_ENDPOINT,
    S3_PRESIGN_URL,
    PRESIGN_URL_ENDPOINT,
    REPORT_URL,
    MATRIMONY_CLOUDFRONT
}